import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import IndexHeroImage from '../components/images/index-hero-image'
import HeroWidget from '../components/hero-widget';
import Footer from '../components/footer'
import locationTimesPartial from '../components/location-times-partial';
import styles from './index.module.scss';

const IndexPage = () => (
	<Layout page="index">
		<div className={styles.page}>

			<HeroWidget image={IndexHeroImage}>
				<h1>Come Home to the Hill!</h1>

				<a href='#visit' className='btn btn-success'>
					Plan your Visit
				</a>

			</HeroWidget>

			<section>
				<a name='visit' className={styles.visitLink}>&nbsp;</a>

				<h1>We would love to see you on Sunday</h1>

				{locationTimesPartial(styles)}

			</section>


			<Footer/>
		</div>
	</Layout>
)

export default IndexPage
